import React from 'react';
import ReactDOM from 'react-dom';
import Description from './description';
import Footer from './footer';
import Home from './home';
import bgimg from './2.jpg';
import './index.css'

ReactDOM.render(
  <>
    <img src={bgimg} alt="background" className='bg-img'/>
    <Home/>
    <Description/>
    <Footer/>
  </>
  ,document.getElementById("root"));
